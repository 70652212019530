/* eslint-disable prettier/prettier */
import * as React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Image from 'gatsby-image';
import { useGraphQL } from '../../hooks/use-graphql';

import {
  Layout,
  SEO,
  Hero,
  Map,
  Contact,
  TheServices,
  OurClients,
  SuitsOverlay,
} from '../../components';
import { useServices } from '../../data';

function SwrGalleryGrid() {
  const {
    swrMain,
    avoca1,
    avoca2,
    avoca3,
    avoca4,
    avoca5,
    avoca6,
    avoca7,
    avoca8,
    avoca9,
    avoca10,
    site: { siteMetadata },
  } = useGraphQL();
  return (
    <article className="bg-navy">
      <div className="relative w-full px-4 py-16 mx-auto max-w-7xl md:pt-36 sm:px-6 lg:px-24 ">
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2 md:col-span-1 ">
            <div className="">
              <div className="relative h-0 aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <Image
                    fluid={avoca1.childImageSharp.fluid}
                    className="flex-1"
                  />
                  <p className='absolute text-3xl text-white top-2 left-2'>Before</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <div className="">
              <div className="relative h-0 aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <Image
                    fluid={avoca2.childImageSharp.fluid}
                    className="flex-1"
                  /><p className='absolute text-3xl text-white top-2 left-2'>Before</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <div className="">
              <div className="relative h-0 aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <Image
                    fluid={avoca3.childImageSharp.fluid}
                    className="flex-1"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <div className="">
              <div className="relative h-0 aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <Image
                    fluid={avoca4.childImageSharp.fluid}
                    className="flex-1"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <div className="">
              <div className="relative h-0 aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <Image
                    fluid={avoca5.childImageSharp.fluid}
                    className="flex-1"
                  /><p className='absolute text-3xl text-white top-2 left-2'>After</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <div className="">
              <div className="relative h-0 aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <Image
                    fluid={avoca6.childImageSharp.fluid}
                    className="flex-1"
                  /><p className='absolute text-3xl text-white top-2 left-2'>After</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <div className="">
              <div className="relative h-0 aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <Image
                    fluid={avoca7.childImageSharp.fluid}
                    className="flex-1"
                  /><p className='absolute text-3xl text-white top-2 left-2'>After</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <div className="">
              <div className="relative h-0 aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <Image
                    fluid={avoca8.childImageSharp.fluid}
                    className="flex-1"
                  /><p className='absolute text-3xl text-white top-2 left-2'>After</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <div className="">
              <div className="relative h-0 aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <Image
                    fluid={avoca9.childImageSharp.fluid}
                    className="flex-1"
                  /><p className='absolute text-3xl text-white top-2 left-2'>After</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <div className="">
              <div className="relative h-0 aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <Image
                    fluid={avoca10.childImageSharp.fluid}
                    className="flex-1"
                  /><p className='absolute text-3xl text-white top-2 left-2'>After</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex justify-end w-full px-4 pt-6 mx-auto max-w-7xl sm:px-6 lg:px-24 ">
        <Link to="/projects" className=" button button-inverted">
          See More Projects
        </Link>
      </div>
    </article>
  );
}

function SwrGallery() {
  const {
    swrMain,
    site: { siteMetadata },
  } = useGraphQL();
  return (
    <article className="bg-navy">
      <div className="w-full px-4 py-16 mx-auto space-y-24 max-w-7xl md:pt-36 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center md:px-12 lg:flex-row">
          <div className="w-full lg:w-1/2">
            <div className="max-w-lg mx-auto">
              <div className="relative h-0 aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <Image
                    fluid={swrMain.childImageSharp.fluid}
                    className="flex-1"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 mt-14 lg:mt-0 ">
            <div className="max-w-lg mx-auto">
              <div className="relative h-0 aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <Image
                    fluid={swrMain.childImageSharp.fluid}
                    className="flex-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-4 py-16 mx-auto space-y-24 max-w-7xl md:py-0 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center md:px-12 lg:flex-row">
          <div className="w-full lg:w-1/2">
            <div className="max-w-lg mx-auto">
              <div className="relative h-0 aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <Image
                    fluid={swrMain.childImageSharp.fluid}
                    className="flex-1"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 mt-14 lg:mt-0 ">
            <div className="max-w-lg mx-auto">
              <div className="relative h-0 aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <Image
                    fluid={swrMain.childImageSharp.fluid}
                    className="flex-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-4 py-16 mx-auto space-y-24 max-w-7xl md:pt-12 md:pb-0 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center md:px-12 lg:flex-row">
          <div className="w-full lg:w-1/2">
            <div className="max-w-lg mx-auto">
              <div className="relative h-0 aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <Image
                    fluid={swrMain.childImageSharp.fluid}
                    className="flex-1"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 mt-14 lg:mt-0 ">
            <div className="max-w-lg mx-auto">
              <div className="relative h-0 aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <Image
                    fluid={swrMain.childImageSharp.fluid}
                    className="flex-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

function Swrclub() {
  const {
    avocaMain,
    site: { siteMetadata },
  } = useGraphQL();
  return (
    <article className="pt-24 bg-navy">
      <div className="w-full px-4 py-16 mx-auto space-y-24 max-w-7xl md:pt-36 md:pb-0 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center md:px-12 lg:flex-row-reverse">
          <div className="w-full lg:w-1/2">
            <div className="max-w-lg mx-auto">
              <div className="relative h-0 aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <Image
                    fluid={avocaMain.childImageSharp.fluid}
                    className="flex-1"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center mt-14 lg:mt-0 lg:w-1/2 md:px-14">
            <div>
              <h2 className="mt-4 text-white heading-2">
                Avoca Beach Bowling Club
              </h2>
              <div className="mt-6 prose text-white">
                <p>
                  61 Bases featuring Empire oak top and sides with black chequer
                  plate footrests and warm LED Lighting.
                </p>
                <p>
                  Matte Gold CNC routed privacy screens with matching dimmable
                  COB LED lighting.
                </p>
              </div>
              <div className="flex mt-8">
                <a href="#contact-form" className="button button-inverted">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

function OurStory() {
  const {
    ourStoryImage,
    site: { siteMetadata },
  } = useGraphQL();

  return (
    <article className="relative pt-24 bg-navy">
      <div className="grid w-full gap-8 px-4 py-24 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:grid-cols-2">
        <Image fluid={ourStoryImage.childImageSharp.fluid} className="flex-1" />
        <div className="my-auto">
          <h2 className="mt-6 text-white heading-2 lg:mt-0">Our Story</h2>
          <div className="mt-6 prose text-white">
            <p>
              {siteMetadata.title} provide quality Electronic Gaming Machine
              (EGM) bases, infill benches, and screens to a variety of gaming
              venues around Australia.
            </p>
            <p>
              We work with some of Australia’s premier Hotels and Registered
              Clubs, creating eye-catching and unique displays using the latest
              in computer-aided design and laser-cutting technology. We create
              bases to your exact size and height requirements.
            </p>
            <p>
              Our team manages projects from start to finish, including laying
              out gaming rooms to assist with ergonomics, light levels, themed
              areas, optimise patron traffic, and adhere to local or state
              gaming regulations.
            </p>
            <p>
              {siteMetadata.shortName} prides itself on providing custom
              solutions for your gaming room and machines. Our bases are
              precision manufactured, efficiently designed, installed with
              minimum delay, and are ongoing cost-effective solutions engineered
              to perform for years and years to come.
            </p>
            <p>
              {siteMetadata.shortName} are proudly Australian, use top quality
              materials, and build to Australian specifications.
            </p>
            <p>
              Need advice or support? Want to know more? Talk to us and we’ll
              give you our extensive expertise on all aspects of EGM bases and
              consoles.
            </p>
          </div>
        </div>
      </div>
    </article>
  );
}

function AvocaBeachBowlingClubPage() {
  const services = useServices();

  const {
    file,
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "avoca-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Avoca Beach Bowling Club" />
      <Hero
        image={file.childImageSharp.fluid}
        heading="Avoca Beach Bowling Club"
      />
      <SuitsOverlay />
      <Swrclub />
      <SwrGalleryGrid />
      {/* <OurStory />
      <TheServices items={services} />
      <OurClients /> */}
      <Contact />
      <Map />
    </Layout>
  );
}

export default AvocaBeachBowlingClubPage;
